import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blocks/Hero"
import Blocks from "../components/HowItWorks/Blocks"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import { MOBILE_HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT } from "../components/Layout/Layout"

const PageContainer = styled.div`
   @media (max-width: 897px) {
    margin-top: calc(4vmax + 94px) ;
  }
    
  margin-top: auto;
  @media (max-width: ${breakpoints.md}) {
    margin-top: calc(2vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const BlocksContainer = styled.div`


  margin-top: ${MOBILE_HEADER_HEIGHT}px;
  width: 100%;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${DESKTOP_HEADER_HEIGHT}px;
    flex-direction: row;
    ${'' /* padding-top: 3rem; */}
  }
`

const NewHowItWorksPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  let videoURL;
  if (page.hero && page.hero.video)
  {
    try{
      videoURL = page.hero.file.url
    } catch(e)
    {
      console.log(e);
    }

  }
  return (
    <Layout bg="#319AD5">
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
      <BlocksContainer>
        {/* <Hero heroData={page.hero} lg/> */}
        <Blocks blocks={page.blocks} />
      </BlocksContainer> 
      </PageContainer>
    </Layout>
  )
}

export default NewHowItWorksPage

export const pageQuery = graphql`
  query NewHowItWorksQuery {
    contentfulPage(slug: { eq: "new-how-it-works" }) {
      blocks {
        __typename
        ... on ContentfulTextVideoBlock { 
            heading
            subHeading
            content
            icon { 
                file {
                    url
                }
            }
            video {
                file {
                    url
                }
            }
            imagePosition
            backgroundColor
            playButton {
              gatsbyImageData(width: 50)
              file { 
                url
              }
            }
        }
        ...on ContentfulStickyCarouselBlock {
            stickyCarouselSlide { 
                headline
                description {
                    raw
                }
                image { 
                    gatsbyImageData(width: 600)
                    file { 
                        url
                    }
                    description
                }
                icon { 
                    gatsbyImageData
                    file { 
                      url
                    }
                    description
                }
            }
        }
        ...on ContentfulHowItWorksInfoBlock{
            heading
            subHeading
            title1
            description1{ 
                raw
            }
            title2
            description2{ 
                raw
            }
            title3
            description3{ 
                raw
            }
            title4
            description4{ 
                raw
            }
        }
        ... on ContentfulZipCodeBlock {
          id
          heading
          subHeading
          icon {
            gatsbyImageData(width: 600)
            file {
              url
            }
          }
          comingSoonImage {
            gatsbyImageData(width: 1200)
          }
          inTheZoneImage {
            gatsbyImageData(width: 1200)
          }
          notInTheZoneImage {
            gatsbyImageData(width: 1200)
          }
          defaultImage {
            gatsbyImageData(width: 1200)
          }
        }
        ...on ContentfulPartnersBlock {
            heading
            subHeading
            description {
                raw
            }
            image { 
                gatsbyImageData(width:500)
                file{
                    url
                }
            }
        }
      }
      metaDescription
      metaTitle
      title
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`
